import React, { useEffect, useState } from 'react'

import List from 'src/components/news/list'
import Article from 'src/components/news/article'

import styles from './index.module.scss'

const NewsPage = () => {
	const [id, setId] = useState(null)

	useEffect(() => {
		if (typeof window !== `undefined`) {
			const param = new URLSearchParams(window.location.search)
			setId(param.get('id'))
		}
	}, [])

	return <div className={styles.news}>{id ? <Article id={id} /> : <List />}</div>
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { ns: { in: ["common", "news"] }, language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`

export default NewsPage

import React, { useState, useEffect } from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import SEO from 'src/components/seo/index'
import Link from 'src/components/link/index'
import Item from './item'

import { get } from 'src/lib/api'

import styles from './list.module.scss'

const MONTH_NAMES = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const List = () => {
	const { language, t } = useI18next()

	const [year, setYear] = useState()
	const [month, setMonth] = useState()
	const [nodes, setNodes] = useState([])

	useEffect(() => {
		if (typeof window !== `undefined`) {
			if (window.location.search) {
				const param = new URLSearchParams(window.location.search)

				if (/^[0-9]+$/.test(param.get('year'))) {
					setYear(parseInt(param.get('year')))
				}
				if (/^[0-9]+$/.test(param.get('month'))) {
					setMonth(parseInt(param.get('month')))
				}
			} else {
				const d = new Date()
				setYear(d.getFullYear())
				setMonth(d.getMonth() + 1)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (year && month) {
			get(`/app/api/news/list`, { lang: language, ny: year, nm: month }).then((data) => {
				setNodes(data.nodes)
			})
		}
	}, [year, month])

	let prevYear = year
	let nextYear = year
	let prevMonth = month - 1
	let nextMonth = month + 1

	let currYear = new Date().getFullYear()
	let currMonth = new Date().getMonth() + 1
	let showNext = true

	if (prevMonth === 0) {
		prevMonth = 12
		prevYear = year - 1
	}

	if (nextMonth === 13) {
		nextMonth = 1
		nextYear = year + 1
	}
	if (nextYear * 100 + nextMonth > currYear * 100 + currMonth) {
		showNext = false
	}

	return (
		<div>
			<SEO title={`News : ${MONTH_NAMES[month - 1]} ${year}`} description={t(`meta.description`)} />

			<div className={styles.list}>
				<h1 className={styles.title}>
					News : {MONTH_NAMES[month]} {year}
				</h1>

				<div className={styles.body}>
					<ul>
						{nodes.map((item, i) => (
							<li key={i}>
								<Item {...item} />
							</li>
						))}
					</ul>
				</div>

				<div className={styles.pager}>
					<div>
						<div className={styles.next}>
							{showNext && (
								<Link to={`/news/?year=${nextYear}&month=${nextMonth}`}>
									{MONTH_NAMES[nextMonth]} {nextYear}
								</Link>
							)}
						</div>

						<span>｜</span>

						<div className={styles.prev}>
							<Link to={`/news/?year=${prevYear}&month=${prevMonth}`}>
								{MONTH_NAMES[prevMonth]} {prevYear}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default List

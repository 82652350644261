import React, { useState, useEffect } from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import SEO from 'src/components/seo/index'
import Date from 'src/components/date/index'
import Link from 'src/components/link/index'
import Latest from './latest'

import { get } from 'src/lib/api'

import styles from './article.module.scss'

const Article = ({ id }) => {
	const { language } = useI18next()

	const [detail, setDetail] = useState(null)

	useEffect(() => {
		if (typeof window !== `undefined`) {
			get(`/app/api/news/get`, { id, lang: language }).then((data) => {
				setDetail(data.detail)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!detail) {
		return null
	}

	return (
		<React.Fragment>
			<SEO title={detail.title} />

			<div className={styles.article}>
				<div className={styles.inner}>
					<div className={styles.info}>
						<ul className={styles.tags}>
							{detail.tags.map((tag, i) => (
								<li key={i}>{tag.name}</li>
							))}
						</ul>
						<Date date={detail.date} className={styles.date} />
					</div>
					<h1 className={styles.title}>{detail.title}</h1>
				</div>

				<div className={styles.mainimage}>
					<img src={detail.mainimage} alt="" />
				</div>

				<div className={styles.content}>
					<div className={styles.inner}>
						<div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
						<div className={styles.back}>
							<Link to="/news/?">一覧に戻る</Link>
						</div>
					</div>
				</div>
			</div>

			<Latest id={id} />
		</React.Fragment>
	)
}

export default Article

import React, { useRef, useContext, useState, useEffect, useCallback } from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import $ from 'jquery'

import { ColsContext } from 'src/hooks/cols/context'

import Link from 'src/components/link/index'
import Date from 'src/components/date/index'

import { get } from 'src/lib/api'

import styles from './latest.module.scss'

const Latest = ({ id }) => {
	const { language } = useI18next()

	const scroller = useRef()
	const wrapper = useRef()
	const title = useRef()

	const { cols } = useContext(ColsContext)

	const [nodes, setNodes] = useState([])
	const [prevHidden, setPrevHidden] = useState(true)
	const [nextHidden, setNextHidden] = useState(false)

	useEffect(() => {
		get(`/app/data/news/latest.json`).then((data) => {
			setNodes(data.nodes.filter((node) => node.id != id))
		})
	}, [id])

	useEffect(() => {
		if (cols !== 'line_sp' && nodes.length > 0) {
			const items = $(wrapper.current).children('li').toArray()

			function resize() {
				wrapper.current.style.width = items.reduce((w, li) => w + $(li).outerWidth(true), 0) + 'px'
				wrapper.current.style.padding = `0 ${(document.body.clientWidth - title.current.clientWidth) / 2}px`
				scroller.current.scrollLeft = 0
				setNextHidden(scroller.current.clientWidth > wrapper.current.clientWidth)
			}

			$(scroller.current)
				.off('scroll')
				.on('scroll', function () {
					const x = scroller.current.scrollLeft
					setPrevHidden(x <= 0)
					setNextHidden(x >= wrapper.current.clientWidth - scroller.current.clientWidth)
				})

			resize()

			window.addEventListener('resize', resize)

			return () => {
				window.removeEventListener('resize', resize)
			}
		} else {
			wrapper.current.style.width = ``
			wrapper.current.style.padding = ``
		}
	}, [cols, nodes])

	const scrollTo = useCallback((index) => {
		const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
		const x = Math.min(index * itemWidth, wrapper.current.clientWidth - scroller.current.clientWidth)
		$(scroller.current).stop().animate({ scrollLeft: x }, 600, 'ioX4')
	}, [])

	const prev = useCallback(() => {
		const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
		const current = Math.ceil(scroller.current.scrollLeft / itemWidth) - 1
		scrollTo(current)
	}, [scrollTo])

	const next = useCallback(() => {
		const itemWidth = $(wrapper.current).children('li').eq(0).outerWidth(true)
		const current = Math.floor(scroller.current.scrollLeft / itemWidth) + 1
		scrollTo(current)
	}, [scrollTo])

	return (
		<div className={styles.latest}>
			<div className={styles.inner}>
				<h2 className={styles.title} ref={title}>
					最新記事
				</h2>
			</div>
			<div className={styles.slider}>
				<div className={styles.scroller} ref={scroller}>
					<ul ref={wrapper}>
						{nodes.slice(0, cols === 'line_sp' ? 3 : 6).map((node, i) => (
							<li key={i}>
								<Link to={`/news/?id=${node.id}`} state={{ back: 1 }} className={styles.item}>
									<div className={styles.thumbnail}>
										<img src={node.mainimage} alt="" />
									</div>
									<div className={styles.name}>{node.title[language]}</div>
									<div className={styles.bottom}>
										<ul className={styles.tags}>
											{node.tags.map((tag, i) => (
												<li key={i}>{tag.name}</li>
											))}
										</ul>
										<Date date={node.date} className={styles.date}></Date>
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
				<button className={styles.prev} onClick={() => prev()} aria-hidden={prevHidden}>
					prev
				</button>
				<button className={styles.next} onClick={() => next()} aria-hidden={nextHidden}>
					next
				</button>
			</div>
		</div>
	)
}

export default Latest

import React from 'react'

import Link from 'src/components/link/index'
import Date from 'src/components/date/index'

import styles from './item.module.scss'

const Item = ({ id, mainimage, title, tags, date }) => {
	return (
		<Link to={`/news/?id=${id}`} state={{ back: 1 }} className={styles.item}>
			<div className={styles.thumbnail}>
				<img src={mainimage} alt="" />
			</div>
			<div className={styles.title}>{title}</div>
			<div className={styles.bottom}>
				{tags && (
					<ul className={styles.tags}>
						{tags.map((tag, i) => (
							<li key={i}>{tag.name}</li>
						))}
					</ul>
				)}
				{date && <Date date={date} className={styles.date}></Date>}
			</div>
		</Link>
	)
}

export default Item
